import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.js";

import { readState } from "@/__main__/app-state.mjs";
import GridList from "@/feature-reskin-probuilds-net/components/GridList.jsx";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import Matchlist from "@/feature-reskin-probuilds-net/components/Matchlist.jsx";
import { FeedSection } from "@/feature-reskin-probuilds-net/Home.style.jsx";
import type {
  ProMatch,
  ReportChampion,
} from "@/feature-reskin-probuilds-net/types.mjs";
import championLink from "@/feature-reskin-probuilds-net/utils/champion-link.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import useStaticChampions from "@/game-lol/utils/use-static-champions.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Home() {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("", "Welcome to Pro Builds")}
      subtitle={t(
        "",
        "PROBUILDS.net is a quick guides tool for League of Legends players. Its primary function is to provide the League of Legends community easy access to Professional Player's builds across all regions",
      )}
    >
      <Feed />
      <PopularChampions />
    </Layout>
  );
}

type Matchlist = ProMatch[];

function Feed() {
  const state = useSnapshot(readState);

  const role = null;
  const proBuildMatchlist = state.lol.proBuildMatchlist[role] as Matchlist;

  return (
    <FeedSection>
      <Matchlist matches={proBuildMatchlist} currRole={null} />
    </FeedSection>
  );
}

function PopularChampions() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const champions = useStaticChampions();

  const proBuildChampionsReport = state.lol
    .proBuildChampionsReport as ReportChampion[];

  // is loading
  if (!proBuildChampionsReport.length) {
    return <Card loading style={{ height: 304 }} />;
  }

  const popularChamps = proBuildChampionsReport
    // this segment could be replaced with an optimized sort that only
    // partially sorts up to n elements, but would be overkill
    .slice() // required to not mutate in place
    .sort((a, b) => b.pickRate - a.pickRate)
    .slice(0, 10)

    .map((champion) => ({
      link: championLink(champion.id),
      image: <ChampionImg id={champion.id} size={48} />,
      title: champions[champion.id]?.name || champion.id,
      subtitle: t("lol:countGame", "{{count, number}} Games", {
        count: champion.matchCount,
      }),
    }));

  return (
    <FeedSection>
      <h2 className="type-title--bold">{t("", "Popular Pro Champions")}</h2>
      <GridList items={popularChamps} />
    </FeedSection>
  );
}

export default Home;

export function meta() {
  return {
    title: [
      "probuildsnet:home.meta.title",
      "ProBuilds - LoL Pro Champion Stats, Teams, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "probuildsnet:home.meta.description",
      "ProBuilds provides League of Legends pro champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
